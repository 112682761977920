.newsletter {
    width: 100%;
}

.newsletter-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.newsletter-form-label {
    font-size: 1.2em;
    line-height: 1.4;
    letter-spacing: -0.02em;
    color: rgba(255, 255, 255, 0.66);
    font-weight: 500;
}

.newsletter-form-input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    font-size: 1em;
    font-weight: 500;
}

.newsletter-submit-button {
    cursor: pointer;
}

.newsletter-submit-button,
.newsletter-success-msg {
    padding: 10px;
    border: none;
    border-radius: 10px;
    background-color: #43c771;
    color: #fff;
    font-size: 1em;
    font-weight: 600;
    text-align: center;
}