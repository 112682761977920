:root {
  --main-bg-color: 23, 23, 25;
  --inner-padding: clamp(15px, 4vw, 60px);
  --outer-padding: clamp(10px, 4vw, 150px);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Inter', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  outline: none;
  list-style: none;
  text-decoration: none;
}

body,
html {
  background: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  color: #fff;
}

#root {
  position: relative;
}

.lasaqa-logo-animation:focus {
  animation: rubberBand 0.6s ease-in;
}

.lasaqa-logo-animation:active {
  animation: none;
}

.scroll-text-reveal-content {
  pointer-events: none;
}

.cursor {
  color: transparent;
  background: linear-gradient(to top, #25742f, #7bd085);
  display: inline-block;
  position: relative;
  width: 2px;
  border-radius: 5px;
  box-shadow: 0 0 6px #486d39ff, 0 0 3px #25742f99;
  opacity: 0;
  -webkit-animation: blink 1.2s infinite;
  animation: blink 1.2s infinite;
  left: 1px;
  top: -1px;
}

.no-pointer-events {
  pointer-events: none;
}

.section {
  padding: 6vh var(--outer-padding) 10vh;
  border-radius: 3rem 3rem 0 0;
  overflow: hidden;
  margin: 0 10px;
}

.section, .footer {
  max-width: 1024px;
  margin: 0 auto;
}

.hero-container {
  max-width: 1400px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .section {
    border-radius: 1.2rem 1.2rem 0 0;
  }
}

/* animations */

@-webkit-keyframes blink {
  0% {
    opacity: 1
  }

  25% {
    opacity: 1
  }

  40% {
    opacity: 0
  }

  60% {
    opacity: 0
  }

  75% {
    opacity: 1
  }

  100% {
    opacity: 1
  }
}

@keyframes blink {
  0% {
    opacity: 1
  }

  25% {
    opacity: 1
  }

  40% {
    opacity: 0
  }

  60% {
    opacity: 0
  }

  75% {
    opacity: 1
  }

  100% {
    opacity: 1
  }
}

@-webkit-keyframes highlight-blink {
  0% {
    background: #666
  }

  30% {
    background: #999
  }

  40% {
    background: #999
  }

  95% {
    background: #666
  }

  100% {
    background: #666
  }
}

@keyframes highlight-blink {
  0% {
    background: #666
  }

  30% {
    background: #999
  }

  40% {
    background: #999
  }

  95% {
    background: #666
  }

  100% {
    background: #666
  }
}

@keyframes rubberBand {
  0% {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, .95, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}