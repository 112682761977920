@keyframes copy-paste-key-sequence-cmdPressAnimation {

  0%,
  39%,
  53%,
  92%,
  100% {
    transform: scale(1) translate(0, 0);
    opacity: 0.45;
  }

  11%,
  28%,
  64%,
  81% {
    transform: scale(0.95) translate(0, 5px);
    opacity: 1;
  }
}

@keyframes copy-paste-key-sequence-cPressAnimation {

  0%,
  6%,
  39%,
  100% {
    transform: scale(1) translate(0, 0);
    opacity: 0.45;
  }

  17%,
  28% {
    transform: scale(0.95) translate(0, 5px);
    opacity: 1;
  }
}

@keyframes copy-paste-key-sequence-vPressAnimation {

  0%,
  59%,
  92%,
  100% {
    transform: scale(1) translate(0, 0);
    opacity: 0.45;
  }

  70%,
  81% {
    transform: scale(0.95) translate(0, 5px);
    opacity: 1;
  }
}

@keyframes copy-paste-key-sequence-cmdPressHighlightAnimation {

  0%,
  39%,
  53%,
  92%,
  100% {
    transform: none;
  }

  11%,
  28%,
  64%,
  81% {
    transform: scale(1.09);
  }
}

@keyframes copy-paste-key-sequence-cPressHighlightAnimation {

  0%,
  6%,
  39%,
  100% {
    transform: none;
  }

  17%,
  28% {
    transform: scale(1.09);
  }
}

@keyframes copy-paste-key-sequence-vPressHighlightAnimation {

  0%,
  59%,
  92%,
  100% {
    transform: none;
  }

  70%,
  81% {
    transform: scale(1.09);
  }
}

.copy-paste-key-sequence {
  display: flex;
  gap: 6px;
  padding: 6px 0;
  justify-content: start;
}

.demo-key-sequence {
  display: flex;
  gap: 6px;
  padding: 6px 0;
  justify-content: center;
}

@media (max-width: 768px) {
  .copy-paste-key-sequence {
    justify-content: center;
  }
}

.copy-paste-key-sequence-cmdKey {
  animation: copy-paste-key-sequence-cmdPressAnimation 4s infinite cubic-bezier(0.38, 0.01, 0.95, 0.51);
}

.copy-paste-key-sequence-cKey {
  animation: copy-paste-key-sequence-cPressAnimation 4s infinite cubic-bezier(0.38, 0.01, 0.95, 0.51);
}

.copy-paste-key-sequence-vKey {
  animation: copy-paste-key-sequence-vPressAnimation 4s infinite cubic-bezier(0.38, 0.01, 0.95, 0.51);
}

/* demo keys */

.demo-key-sequence .key-key {
  --scale-x: 1.09;
}

.demo-key-sequence .key-key::before {
  display: none;
}

.demo-key-sequence .space-key {
  --scale-x: 1.02;
  width: 300px;
}

.demo-key-sequence .space-key i {
  font-size: 0.8em;
  color: #555;
  font-weight: 600;
  letter-spacing: 0.05em;
}

.demo-key-sequence-modifier {
  animation: demo-key-sequence-modifierPressAnimation 2s infinite cubic-bezier(0.38, 0.01, 0.95, 0.51);
}

.demo-key-sequence-key {
  animation: demo-key-sequence-keyPressAnimation 2s infinite cubic-bezier(0.38, 0.01, 0.95, 0.51);
}

@keyframes demo-key-sequence-modifierPressAnimation {

  0%,
  69%,
  100% {
    transform: scale(1) translate(0, 0);
    opacity: 0.85;
  }

  13%,
  56% {
    transform: scale(0.95) translate(0, 5px);
    opacity: 1;
  }
}

@keyframes demo-key-sequence-keyPressAnimation {

  0%,
  13%,
  69%,
  100% {
    transform: scale(1) translate(0, 0);
    opacity: 0.85;
  }

  25%,
  56% {
    transform: scale(0.95) translate(0, 5px);
    opacity: 1;
  }
}

@keyframes demo-key-sequence-modifierPressHighlightAnimation {

  0%,
  69%,
  100%{
    transform: none;
  }

  13%,
  56% {
    transform: scale(1.09);
  }
}

@keyframes demo-key-sequence-keyPressHighlightAnimation {

  0%,
  13%,
  69%,
  100% {
    transform: none;
  }

  25%,
  56% {
    transform: scale(var(--scale-x), 1.09);
  }
}