.scroll-progress-container {
  display: flex;
  position: absolute;
  inset: 0;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 100;
  pointer-events: none;
}

.scroll-progress-bar-wrapper {
  padding: 10px 20px 20px;
  width: 100%;
  height: 55px;
}

.scroll-progress-bar {
  position: relative;
  background-color: #000;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 7px;
  pointer-events: all;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.33);
}

.scroll-progress {
  background: #43c771;
  height: 100%;
  border-radius: 7px;
  border: 2px solid #000;
  position: relative;
  overflow: hidden;
}

.scroll-progress::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 15%;
  min-width: 20px;
  max-width: 100px;
  background: linear-gradient(to right, rgba(233, 255, 0, 0) 10%, rgba(233, 255, 0, 0.63));
}

.scroll-progress-circle {
  position: absolute;
  top: 50%;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
  opacity: 0.3;
  transition: transform 0.15s ease-out, opacity 0.15s ease-out;
  cursor: pointer;
}

.scroll-progress-circle::after {
  content: '';
  position: absolute;
  inset: -100%;
}

.scroll-progress-circle:hover {
  transform: translate(-50%, -50%) scale(1.2);
  opacity: 0.7;
}

.scroll-progress-circle.done:hover, .scroll-progress-circle.current:hover {
  transform: translate(-50%, -50%) scale(1.45);
}

.scroll-progress-circle.done, .scroll-progress-circle.current {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1.2);
}

.scroll-progress-circle::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 50%;
  border: 0.5px solid #fff;
  opacity: 0;
  transition: transform 0.1s ease-in, opacity 0.1s ease-in;
}

.scroll-progress-circle.current::before {
  opacity: 1;
  transform: scale(2);
}