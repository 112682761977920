.footer {
    padding: 5rem var(--outer-padding);
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin: 0 10px 10px;
    box-shadow: 0 -50px 60px 0 #000;
    position: relative;
}

.footer::before {
    content: '';
    position: absolute;
    height: 1px;
    top: 20px;
    left: 15%;
    right: 15%;
    background: linear-gradient(to right, #fff2 5%, #fff7, #fff2 95%);
    opacity: 0.65;
}

.footer>div:last-child {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
}

.footer .heart {
    color: #43c771;
}

.footer a {
    color: #fff;
    display: flex;
    text-decoration: none;
    align-items: center;
    gap: 5px;
}

.footer p {
    text-align: left;
}

@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        justify-content: center;
        gap: 32px;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .footer p {
        text-align: center;
    }
}


.footer>div:last-child a {
    border-bottom-color: rgb(255, 255, 255);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    cursor: auto;
    display: block;
    font-size: 1em;
    line-height: 24px;
    padding-bottom: 8px;
    tab-size: 4;
    text-align: left;
    text-decoration: none;
    text-decoration-line: none;
    transition-duration: 0.2s;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}