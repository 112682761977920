.section-title {
  padding: 6px 0;
  font-size: clamp(1.9rem, 8vw, 2.5em);
  font-weight: 700;
  letter-spacing: -0.04em;
  line-height: 1.2;
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(#fff 35%, #fff0);
  text-align: center;
  transition: transform 0.05s ease;
}