.bio-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #000;
    min-height: 100vh;
}

.bio-container::before {
    content: '';
    position: absolute;
    background: radial-gradient(circle at 50% 0%, color(srgb 0.6205 0.6205 0.6205 / 0.71), rgba(23, 23, 25, 0) 40%);
    z-index: 0;
    width: 100%;
    aspect-ratio: 1;
    top: 0;
    transform: scale(calc(var(--background-transition) * 1.8));
    opacity: calc(var(--background-transition) * 4);
    transform-origin: 50% 0%;
}

@media (max-width: 768px) {
    .bio-container::before {
        height: 100%;
        width: initial;
    }
}

.bio-container>div {
    z-index: 1;
    position: relative;
}

.bio-img-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.bio-img-container img {
    display: block;
    width: 25%;
    max-width: 250px;
    min-width: 100px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: opacity 0.2s ease, transform 0.2s ease;
}

@media (max-width: 768px) {
    .bio-img-container img {
        width: 60%;
    }
}

.bio-img-container img:last-child {
    position: absolute;
    opacity: 0;
}

.bio-img-container img:first-child {
    position: relative;
    z-index: 1;
}

.bio-img-container.img-revealed img:first-child {
    transform: scale(0.8) translate(5%, -2%);
    opacity: 0;
}

.bio-img-container.img-revealed img:last-child {
    opacity: 1;
}

.bio {
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.1em;
    line-height: 1.5;
    color: rgba(255, 255, 255, 0.66);
    font-weight: 500;
    padding: var(--inner-padding);
    border-radius: 24px;
    display: flex;
    flex-direction: row;
    gap: 40px;
    background: #000;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12), 0px 24px 40px rgba(0, 0, 0, 0.12);
    color: white;
}

@media (max-width: 768px) {
    .bio {
        border-radius: 14px;
    }
}

.bio-description b {
    font-size: 1.8em;
    margin-right: 3px;
    color: #43c771;
}

.bio>* {
    flex: 1;
}

/* .bio>*:last-child {
    max-width: 40%;
} */

/* 
.bio>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
}

.bio>div:first-child {
    align-items: start;
    flex-direction: row;
    align-items: start;
    gap: 20px;
}

.bio>div:first-child img {
    mask-image: linear-gradient(to bottom, white 80%, transparent 99%);
    -webkit-mask-image: linear-gradient(to bottom, white 80%, transparent 99%);
    width: 30%;
    height: 30%;
}

.bio>div:last-child {
    width: 100%;
    align-items: center;
    max-width: 35%;
} */


/* 
@media (max-width: 768px) {
    .bio {
        flex-direction: column;
        gap: 4vh;
    }

    .bio>div {
        justify-content: stretch;
        align-items: initial !important;
        max-width: none !important;
    }
} */