.mac-os-desktop-desktop {
  color: #fff;
  display: flex;
  position: absolute;
  inset: 0;
  overflow: hidden;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.25);
  transition: transform 0.1s ease-out, width 0.05s ease-out;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mac-os-desktop-desktop-content {
  color: #fff;
  flex: 1;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.mac-os-desktop-desktop-content>* {
  position: absolute;
  scale: 0.75;
}

.mac-os-desktop-top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #000;
  height: 26px;
  padding: 0 20px;
  pointer-events: none;
  z-index: 100;
}

.mac-os-desktop-logo {
  font-size: 1.2em;
  font-weight: bold;
}

.mac-os-desktop-info {
  display: flex;
}

.mac-os-desktop-time {
  font-size: 14px;
  font-weight: 500;
  margin-left: 6px;
}

.mac-os-desktop-system-icon {
  padding: 0 8px;
  font-size: 1.1em;
  position: relative;
}

.mac-os-desktop-system-icon.wifi {
  transition: color 0.25s ease-in;
}

.mac-os-desktop-system-icon.wifi::before {
  content: '';
  position: absolute;
  background: #fff;
  height: 1px;
  transform: rotateZ(42deg);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.68);
  width: 4%;
  top: 73%;
  left: 68%;
  border-radius: 10px;
  opacity: 0;
  transition: all 0.25s ease-in;
}

.mac-os-desktop-system-icon.wifi.disabled {
  color: rgba(255, 255, 255, 0.5);
}

.mac-os-desktop-system-icon.wifi.disabled::before {
  width: 65%;
  top: 38%;
  left: 17%;
  opacity: 1;
}

.mac-os-desktop-lasaqa-icon {
  padding: 0 8px;
  opacity: 0;
}