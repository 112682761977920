.how-it-works-container {
    height: 200vh;
}

.how-it-works {
    height: 100vh;
    display: flex;
    position: sticky;
    top: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4vh;
}

.how-it-works::before {
    content: '';
    position: absolute;
    width: 100%;
    aspect-ratio: 1;
    top: 0;
    background: radial-gradient(circle at 50% 0%, color(srgb 0.1449 0.4588 0.3143), transparent 40%);
    transform: scale(calc(var(--background-transition) * 1.8));
    opacity: calc(var(--background-transition) * 4);
    transform-origin: 50% 0%;
}

@media (max-width: 768px) {
    .how-it-works::before {
        height: 100%;
        width: initial;
    }
}

.how-it-works-screen {
    overflow: hidden;
    flex: 1;
    height: 100%;
    max-width: 100%;
    aspect-ratio: 16/10;
    background: linear-gradient(123deg, rgba(101, 234, 147, 0.94) 16.39%, rgba(68, 120, 65, 1));
    border-radius: 14px;
    position: relative;
    box-shadow: 0 8px 30px 3px #ffffff0d;
    transition: opacity 0.05s ease-out, transform 0.05s ease-out;
    will-change: auto;
}

.how-it-works-screen::after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    pointer-events: none;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.57) inset;
    mix-blend-mode: soft-light;
}