.mac-os-window-window {
  width: 540px;
  height: 380px;
  display: flex;
  flex-direction: column;
  background: #1e1e1e;
  border-radius: 12px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.3);
  padding: 8px;
  border: 1px solid #444;
  color: #fff;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  transition: scale 0.1s ease-out, translate 0.1s ease-out;
}

.mac-os-window-window-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0;
}

.mac-os-window-header-spacer {
  flex: 1;
}

.mac-os-window-content-container {
  padding: 15px 10px 15px;
  flex: 1;
  line-height: 1.5;
  font-weight: 500;
}

.mac-os-window-buttons-container {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.mac-os-window-window-title {
  font-size: 0.7em;
  color: #999;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: center;
  flex-grow: 0;
}

.mac-os-window-button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: none;
  margin-right: 5px;
}

.mac-os-window-button:nth-child(1) {
  background: #ff605c;
}

.mac-os-window-button:nth-child(2) {
  background: #ffbd44;
}

.mac-os-window-button:nth-child(3) {
  background: #00ca4e;
}