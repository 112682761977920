.logo-icon-container {
    position: absolute;
    cursor: default;
    will-change: auto;
    pointer-events: all;
    left: var(--logo-offset-left);
    top: var(--logo-offset-top);
    transition: transform 0.25s ease-out;
}

.logo-icon-container.sticky {
    transform: translate(calc(var(--logo-offset-left)*-1), calc(var(--logo-offset-top)*-1)) scale(0.45) !important;
    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(0.92, 0.21, 0.06, 0.85);
    cursor: pointer;
    --icon-transition: 0 !important;
}

.logo-icon-container.sticky::before {
    content: '';
    position: absolute;
    inset: -10px;
    border-radius: 50%;
    background: #0000003d;
    backdrop-filter: blur(40px) brightness(1.75);
    -webkit-backdrop-filter: blur(40px) brightness(1.75);
    pointer-events: none;
}

.logo-icon-container.sticky::after {
    content: '';
    position: absolute;
    inset: -10px;
    border-radius: 50%;
    border: 3px solid #ffffff7f;
    mix-blend-mode: overlay;
    pointer-events: none;
}

.logo-icon {
    display: block;
    width: clamp(100px, 50vw, 180px);
    aspect-ratio: 1;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.logo-icon:first-child {
    position: absolute;
    opacity: var(--icon-transition);
}

.logo-icon:last-child {
    position: relative;
    opacity: calc(1 - var(--icon-transition));
}