.feature {
  gap: 10px;
  text-align: left;
  background-image: none;
  flex-direction: column;
  align-items: flex-start;
  padding: calc(var(--inner-padding) * 0.75);
  display: flex;
  position: relative;
  z-index: 0;
  border-radius: 24px;
  min-height: 300px;
  background: radial-gradient(circle at 50% 0%, transparent 56.72%, color(srgb 0.0923 0.1333 0.106) 92%);
  box-shadow: 0 30px 40px rgba(66, 199, 112, 0.05);
}

.feature.spanning {
  /* justify-content: center; */
}

.feature.constrained {
  justify-content: start;
}

@media (max-width: 768px) {
  .feature {
    border-radius: 14px;
    padding: var(--inner-padding);
  }
}

.feature-headline {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  mix-blend-mode: overlay;
}

.feature-description {
  color: #fff9;
  font-size: 17px;
  line-height: 25px;
}