.hero-container {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: transform 0.1s ease-out;
  padding: 0 calc(10px + var(--outer-padding));
}

.hero-column {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
}

.hero-column:first-child>div {
  position: relative;
}

.hero-column:last-child {
  flex-direction: column;
  gap: 14px;
  height: 100%;
  align-items: start;
  justify-content: center;
  text-align: left;
}

.hero-headline,
.hero-highlighted-word {
  font-size: clamp(2.8rem, 6vw, 5.5rem);
  line-height: 1.2em;
  cursor: default;
  letter-spacing: -0.03em;
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
}

.hero-subline {
  font-size: clamp(0.85rem, 1.4vw, 2rem);
  line-height: 1.4em;
  padding: 5px 20% 20px 0;
  color: #f1f1f1;
  font-weight: 400;
  cursor: default;
}

.hero-headline {
  background-image: linear-gradient(white 26.47%, rgba(255, 255, 255, 0.5));
}

.hero-highlighted-word {
  background-image: linear-gradient(to top, rgb(12, 194, 136) 15.13%, rgb(255, 220, 80) 93.7%);
}

.hero-icon {
  display: block;
  width: clamp(100px, 50vw, 180px);
  aspect-ratio: 1;
  cursor: default;
}

@media (max-width: 768px) {
  .hero-container {
    flex-direction: column;
  }

  .hero-column:last-child {
    padding-bottom: 100px;
    align-items: initial;
    text-align: center;
    gap: 20px;
  }

  .hero-icon {
    align-self: center;
  }

  .hero-column:first-child {
    display: none;
  }

  .hero-subline {
    padding: 5px 20px 20px;
  }
}