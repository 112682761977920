.preview-window-preview-panel {
    background: #1e1e1e1e;
    width: clamp(250px, calc(100vw / 3), 300px);
    aspect-ratio: 9 / 16;
    border-radius: 15px;
    font-weight: 400;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    position: relative;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.preview-window-preview-panel::before {
    content: '';
    inset: 0;
    border-radius: 15px;
    position: absolute;
    z-index: -1;
    backdrop-filter: blur(20px) saturate(200%) brightness(50%) contrast(90%);
    -webkit-backdrop-filter: blur(20px) saturate(200%) brightness(50%) contrast(90%);
}

.preview-window-preview-panel::after {
    content: '';
    inset: 0;
    position: absolute;
    border-radius: 15px;
    pointer-events: none;
    z-index: 2;
    box-shadow: inset 1px 1px 1px 0 #ffffff3a, inset -1px -1px 1px 0 #ffffff22;
}

.preview-window-preview-header {
    height: clamp(46px, 14%, 76px);
    border-bottom: 1px solid #ffffff2a;
    display: flex;
    justify-items: center;
    align-content: center;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    padding: 0 1rem;
    position: sticky;
    top: 0;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    z-index: 1;
}

.preview-window-preview-content-container {
    overflow-y: scroll;
    position: absolute;
    height: 100%;
    top: 0;
    padding-top: clamp(46px, calc(14% * 16 / 9), 76px);
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 100%;
}

.preview-window-preview-content-container::-webkit-scrollbar {
    display: none;
}

.preview-window-preview-content {
    display: flex;
    flex-direction: column;
    justify-items: start;
    align-content: center;
    align-items: center;
    gap: 0.6rem;
    padding: 0.7rem;
}

.preview-window-preview-logo {
    opacity: 0.5;
    display: block;
    object-fit: contain;
    aspect-ratio: 1;
    height: 50%;
}

.preview-window-preview-search {
    display: block;
}

.preview-window-preview-search-input {
    display: block;
    height: 100%;
    opacity: 0.7;
    background: none;
    border: none;
    outline: none;
    color: #fff;
    font-size: 2em;
    width: 100%;
}

.preview-window-preview-search-input::placeholder {
    opacity: 0.35;
    color: #fff;
}