.pricing {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 4vw;
  min-height: 100vh;
  scroll-margin-top: 10px;
}

.pricing::before {
  content: '';
  position: absolute;
  width: 100%;
  aspect-ratio: 1;
  top: 0;
  background: radial-gradient(circle at 50% 0%, #5d40ff, rgba(0, 0, 0, 0) 40%);
  transform: scale(calc(var(--background-transition) * 1.8));
  opacity: calc(var(--background-transition) * 4);
  transform-origin: 50% 0%;
}

@media (max-width: 768px) {
  .pricing::before {
    height: 100%;
    width: initial;
  }
}

.pricing-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 720px;
}

.pricing-content {
  text-align: left;
  display: inline-block;
  vertical-align: middle;
  border-radius: 24px;
  background: radial-gradient(circle at center top, #000, #1c1a3d 80%);
  width: 60%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12), 0px 24px 40px rgba(0, 0, 0, 0.12);
  position: relative;
  z-index: 2;
}

@media (max-width: 768px) {
  .pricing-content {
      border-radius: 14px;
  }
}

.pricing-details {
  padding: var(--inner-padding);
}

.pricing-price h3 {
  font-weight: 900;
  font-size: 36px;
  line-height: 44px;
  color: #ededed;
}

.pricing-price h3 span {
  text-decoration: line-through;
  font-weight: 700;
  color: #343070;
}

.pricing-price h3 strong {
  font-weight: 900;
}

.pricing-price h3 sub {
  font-size: 18px;
  line-height: 24px;
  vertical-align: bottom;
  display: inline-block;
  padding: 0 0 4px 6px;
}

.pricing-price h5 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #43c771;
  background: rgba(67, 199, 113, 0.2);
  padding: 8px 16px;
  display: inline-block;
  text-align: center;
  border-radius: 12px;
  margin-top: 12px;
}

.pricing-details p {
  font-size: 18px;
  line-height: 24px;
  color: #928ebf;
  margin-bottom: 40px;
  font-weight: 600;
  margin-top: 8px;
}

.pricing-details ul li {
  display: block;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #ededed;
  margin-bottom: 24px;
}

.pricing-details ul li::before {
  content: "";
  display: inline-block;
  vertical-align: text-top;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.0607 7.06066C21.6464 6.47487 21.6464 5.52513 21.0607 4.93934C20.4749 4.35355 19.5251 4.35355 18.9393 4.93934L21.0607 7.06066ZM9 17L7.93934 18.0607C8.52513 18.6464 9.47487 18.6464 10.0607 18.0607L9 17ZM5.06066 10.9393C4.47487 10.3536 3.52513 10.3536 2.93934 10.9393C2.35355 11.5251 2.35355 12.4749 2.93934 13.0607L5.06066 10.9393ZM18.9393 4.93934L7.93934 15.9393L10.0607 18.0607L21.0607 7.06066L18.9393 4.93934ZM10.0607 15.9393L5.06066 10.9393L2.93934 13.0607L7.93934 18.0607L10.0607 15.9393Z' fill='%2343c771'/%3E%3C/svg%3E%0A");
}

.pricing-buttons {
  padding: 0 var(--inner-padding) var(--inner-padding);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pricing-trial-button {
  display: block;
  text-align: center;
  width: 100%;
  padding: 12px 24px;
  background: #5d40ff;
  color: #fff;
  border-radius: 14px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
}

.pricing-buy-button {
  display: block;
  text-align: center;
  width: 100%;
  padding: 12px 24px;
  color: #ededed;
  box-shadow: 0 0 0 2px rgba(37, 37, 75, 1) inset;
  font-weight: 700;
  border-radius: 14px;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .pricing {
    gap: 4vh;
  }

  .pricing-content {
    flex-direction: column;
    width: 100%;
  }
}











/* */
/* 
.pricing {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 4vw;
  min-height: 100vh;
  scroll-margin-top: 10px;
}

.pricing::before {
  content: '';
  position: absolute;
  width: 100%;
  aspect-ratio: 1;
  top: 0;
  background: radial-gradient(circle at 50% 0%, #5d40ff, rgba(0, 0, 0, 0) 40%);
  transform: scale(calc(var(--background-transition) * 1.8));
  opacity: calc(var(--background-transition) * 4);
  transform-origin: 50% 0%;
}

@media (max-width: 768px) {
  .pricing::before {
    height: 100%;
    width: initial;
  }
}

.pricing-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 720px;
}

.pricing-content {
  text-align: left;
  display: inline-block;
  vertical-align: middle;
  border-radius: 24px;
  background: #fff;
  width: 60%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12), 0px 24px 40px rgba(0, 0, 0, 0.12);
  position: relative;
  z-index: 2;
}

.pricing-details {
  padding: 40px;
}

.pricing-price h3 {
  font-weight: 900;
  font-size: 36px;
  line-height: 44px;
  color: #333;
}

.pricing-price h3 span {
  text-decoration: line-through;
  opacity: 0.32;
  font-weight: 700;
}

.pricing-price h3 strong {
  font-weight: 900;
}

.pricing-price h3 sub {
  font-size: 18px;
  line-height: 24px;
  vertical-align: bottom;
  display: inline-block;
  padding: 0 0 4px 6px;
}

.pricing-price h5 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #43c771;
  background: rgba(67, 199, 113, 0.2);
  padding: 8px 16px;
  display: inline-block;
  text-align: center;
  border-radius: 12px;
  margin-top: 12px;
}

.pricing-details p {
  font-size: 18px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 40px;
  font-weight: 600;
  margin-top: 8px;
}

.pricing-details ul li {
  display: block;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #333;
  margin-bottom: 24px;
}

.pricing-details ul li::before {
  content: "";
  display: inline-block;
  vertical-align: text-top;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.0607 7.06066C21.6464 6.47487 21.6464 5.52513 21.0607 4.93934C20.4749 4.35355 19.5251 4.35355 18.9393 4.93934L21.0607 7.06066ZM9 17L7.93934 18.0607C8.52513 18.6464 9.47487 18.6464 10.0607 18.0607L9 17ZM5.06066 10.9393C4.47487 10.3536 3.52513 10.3536 2.93934 10.9393C2.35355 11.5251 2.35355 12.4749 2.93934 13.0607L5.06066 10.9393ZM18.9393 4.93934L7.93934 15.9393L10.0607 18.0607L21.0607 7.06066L18.9393 4.93934ZM10.0607 15.9393L5.06066 10.9393L2.93934 13.0607L7.93934 18.0607L10.0607 15.9393Z' fill='%235d40ff'/%3E%3C/svg%3E%0A");
}

.pricing-buttons {
  padding: 0 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pricing-trial-button {
  display: block;
  text-align: center;
  width: 100%;
  padding: 12px 24px;
  background: #5d40ff;
  color: #fff;
  border-radius: 14px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
}

.pricing-buy-button {
  display: block;
  text-align: center;
  width: 100%;
  padding: 12px 24px;
  color: #5d40ff;
  box-shadow: inset 0 0 0 2px #5d40ff;
  font-weight: 700;
  border-radius: 14px;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .pricing {
    gap: 4vh;
  }

  .pricing-content {
    flex-direction: column;
    width: 100%;
  }
} */