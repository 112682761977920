@keyframes scroll-indicator-bounce {

  0%,
  20%,
  60%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-5px);
  }
}

.scroll-indicator-container {
  position: fixed;
  z-index: 10;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}

.scroll-indicator-container.inactive {
  pointer-events: none;
}

@media (max-width: 768px) {
  .scroll-indicator-container {
    bottom: 4px;
  }
}

.scroll-indicator-icon {
  font-size: 2.5em;
  color: rgba(255, 255, 255, 0.65);
  animation: scroll-indicator-bounce 4s infinite;
  transition: opacity 0.15s ease-in;
  cursor: pointer;
}

@media (max-width: 768px) {
  .scroll-indicator-icon {
    font-size: 3em;
    color: rgba(255, 255, 255, 0.9);
    text-shadow: 0 -3px 10px rgba(0, 0, 0, 0.35);
  }
}