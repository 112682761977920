.demo-shortcuts-container {
    display: flex;
    position: absolute;
    inset: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 20;
    background: linear-gradient(to bottom right, #0f543ebd 20.59%, #0f543e80);
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s ease-in;
}

.demo-shortcuts-container.shown {
    opacity: 1;
    pointer-events: all;
}

.demo-shortcuts-container.shown > * {
    animation: fadeIn 0.2s ease-in forwards;
}

.scroll-text-reveal-content span {
    font-family: 'Roboto Mono', monospace;
}

.scroll-text-reveal-content span:not(.cursor) {
    background: transparent;
}

.scroll-text-reveal-content.highlighted span:not(.cursor) {
    background: rgba(90, 149, 70, 0.67);
    transition: background .15s ease-in;
}

.scroll-text-prev {
    position: absolute;
    animation: vanishOut 0.25s ease-in forwards;
}

.scroll-text-prev span {
    background: rgb(90, 149, 70) !important;
}

.scroll-text-modified {
    position: absolute;
    opacity: 0;
    animation: fadeIn 0.25s ease-in 0.15s forwards;
}

@keyframes vanishOut {
    0% {
        opacity: 1;
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-filter: blur(0);
        filter:blur(0)
    }

    to {
        opacity: 0;
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        -webkit-filter: blur(20px);
        filter:blur(20px)
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}