.cta-button-button {
  font-size: 1.2em;
  border: none;
  padding: 12px 24px;
  border-radius: 14px;
  background: #5d40ff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12), 0px 24px 40px rgba(0, 0, 0, 0.12);
  color: white;
  text-decoration: none;
  transition: transform 0.2s ease-in-out;
  font-weight: 600;
  line-height: 1.1em;
  cursor: pointer;
  display: block;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cta-button-button:hover {
  transform: scale(1.02);
}

.cta-button-button:active {
  transform: scale(0.98);
}