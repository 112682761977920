.preview-tool-container {
  background: rgba(217, 217, 217, 0.25);
  border-radius: 10px;
  width: 100%;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  transition: background 0.15s ease-in;
  position: relative;
}

.preview-tool-container.active {
  background: #3f9d57;
}

.preview-tool-container.highlightable::before {
  content: '';
  position: absolute;
  inset: -5px;
  border-radius: 15px;
  border: 3px solid #319d4c;
  opacity: 0;
  transition: opacity 0.15s ease-in;
}

.preview-tool-container.highlightable.highlighted::before,
.preview-tool-container.highlightable.highlighted:hover::before {
  opacity: 1;
}

.preview-tool-container.highlightable:hover::before {
  opacity: 0.5;
}

.preview-tool-tool-header {
  display: flex;
  align-items: center;
  padding: 8px;
}

.preview-tool-tool-title {
  font-size: 1em;
  font-weight: 700;
  flex: 1;
}

.preview-tool-tool-body {
  padding: 8px;
  background: #0004;
  font-size: 0.8em;
  font-weight: 400;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.preview-tool-play-button {
  display: block;
  position: relative;
  font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' -25, 'opsz' 24;
  padding: 2px 2px;
  line-height: 25px;
  border: none;
  border-radius: 8px;
  background: #0004;
  color: #fff;
  cursor: pointer;
  transition: background 0.15s ease-in;
}

.preview-tool-container.active .preview-tool-play-button {
  background: transparent;
}

.preview-tool-container.pulse:not(.active) .preview-tool-play-button::before {
  position: absolute;
  content: '';
  border-radius: 8px;
  inset: 0;
  background: #00000033;
  animation: pulse 2.5s ease-out infinite;
}

@keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(1.2);
  }

  20% {
    opacity: 1;
  }

  60%,
  100% {
    transform: scale(1);
    opacity: 0;
  }
}