.faq {
  background: #000;
  box-shadow: 0 -50px 60px 0 #000;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 80vh;
  padding-bottom: 20vh !important
}

.faq-content {
  justify-content: start;
  align-items: stretch;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.faq .question-container {
  padding: 5px 0 10px;
  position: relative;
  transition: padding 0.2s ease;
}

.faq .question-container::after {
  content: '';
  position: absolute;
  height: 1px;
  width: 100%;
  bottom: 0;
  left: 0;
  background: linear-gradient(to right, #fff2 5%, #fff7, #fff2 95%);
}

.faq .accordion .title {
  cursor: pointer;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 1em;
  font-size: 1.4em;
  font-weight: 500;
  line-height: 1.2;
}

.faq .accordion .title .toggle-expand-arrow {
  transition: transform 0.3s ease;
  left: -5px;
  position: relative;
}

.faq .accordion .active {
  padding-bottom: calc(1em + 10px);
  box-sizing: content-box;
}

.faq .accordion .active .title .toggle-expand-arrow {
  transform: rotate(180deg);
}

.faq .accordion .content {
  overflow: hidden;
  padding: 0 calc(1.25em + 24px);
  position: relative;
  font-size: 1.2em;
  line-height: 1.3;
  font-weight: 400;
  height: 0;
  transition: height 0.2s ease;
}

.faq .accordion .content a {
  color: #fff;
  text-decoration: underline;
}

.faq .accordion .active .content {
  height: var(--max-height);
}

.faq-title {
  font-size: 1.5em;
  margin-bottom: 1em;
}