.features {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4vh;
    padding-bottom: 20vh !important;
    box-shadow: 0 -50px 60px 0 #000;
    background: #000;
}

.features-container {
    display: grid;
    gap: 3vh;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(3, minmax(40px, auto));
    grid-template-areas: "a a c" "e b f" "e d d";
}

@media (max-width: 768px) {
    .features-container {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(6, minmax(40px, auto));
        grid-template-areas: "a" "b" "c" "d" "e" "f";
    }
}

.features-container .grid-area-a {
    grid-area: a;
}

.features-container .grid-area-b {
    grid-area: b;
}

.features-container .grid-area-c {
    grid-area: c;
}

.features-container .grid-area-d {
    grid-area: d;
}

.features-container .grid-area-e {
    grid-area: e;
}

.features-container .grid-area-f {
    grid-area: f;
}

@media screen and (max-width: 991px) {
    .features {
        height: auto;
    }
}