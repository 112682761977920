.key-key {
    position: relative;
    display: inline-block;
    width: clamp(3rem, 6vw, 4rem);
    height: clamp(3rem, 6vw, 4rem);
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
    z-index: 1;
    --key-border-radius: clamp(7px, 2vw, 14px);
}

.key-key::after {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(rgba(255, 255, 255, 0.38), rgba(0, 0, 0, 0.39)), linear-gradient(#282828, #202020);
    z-index: -2;
    border-radius: var(--key-border-radius);
    box-shadow: inset -8px 0 8px rgba(0, 0, 0, 0.15), inset -8px 0 8px rgba(0, 0, 0, 0.25), 0 5px 10px 2px rgba(0, 0, 0, 0.61);
}

.key-key i {
    position: absolute;
    border-radius: var(--key-border-radius);
    overflow: hidden;
    color: #acacac;
    font-style: normal;
    font-size: clamp(1.15em, 2vw, 1.5em);
    text-transform: uppercase;
    width: 100%;
    height: 100%;
    display: flex;
    top: 0;
    left: 0;
    font-weight: 600;
    justify-content: center;
    align-items: center;
}

.key-key i::before {
    content: '';
    position: absolute;
    inset: 1px;
    background: linear-gradient(#383838, #121212);
    border-radius: var(--key-border-radius);
    box-shadow: 10px 5px 10px rgba(0, 0, 0, 0.15);
    z-index: -1;
}