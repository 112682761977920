.demo-explanations-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.demo-explanation-container:not(:first-child) {
  position: absolute;
  top: 0;
}

.demo-explanation-container.shown {
  animation: fadeIn 0.2s ease-in forwards;
}

.demo-explanation-container.hidden {
  animation: fadeOut 0.2s ease-in forwards;
}

.demo-explanation-title {
  font-weight: 700;
  font-size: 1.2em;
  letter-spacing: -0.025em;
  line-height: 1.3;
}

.demo-explanation-description {
  font-weight: 400;
  font-size: 0.8em;
  line-height: 1.5;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}