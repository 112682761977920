.background {
    z-index: 0;
    height: 100vh;
    width: 100vw;
    position: absolute;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
}

.background::before {
    content: '';
    position: absolute;
    width: 100%;
    aspect-ratio: 1;
    bottom: 0;
    background: radial-gradient(circle at 50% 100%, rgb(34, 34, 52), transparent 50%);
    transform: scale(calc(var(--background-transition)));
    opacity: calc(var(--background-transition) * 4);
    transform-origin: 50% 100%;
    z-index: -2;
}

@media (max-width: 768px) {
    .background::before {
        height: 100%;
        width: initial;
    }
}

.background-blob:nth-child(1) {
    position: absolute;
    border-radius: 30%;
    background-image: linear-gradient(to top, #004848, #3f9d57);
    height: 35%;
    aspect-ratio: 1;
    position: absolute;
    rotate: 20deg;
    bottom: 10%;
    left: 20%;
    z-index: -1;
    animation: wobble 40s infinite ease-in-out;
}

.background-blob:nth-child(2) {
    position: absolute;
    border-radius: 30%;
    background-image: linear-gradient(rgb(133, 53, 117), #b19926);
    height: 60%;
    aspect-ratio: 1;
    position: absolute;
    rotate: 40deg;
    top: 5%;
    left: -15%;
    z-index: -2;
    animation: rotate 80s infinite linear;
}

@media (max-width: 768px) {
    .background-blob:nth-child(1) {
        height: 30%;
        bottom: 10%;
        right: -3%;
        left: initial;
    }

    .background-blob:nth-child(2) {
        height: 50%;
        top: 5%;
        left: -30%;
    }
}

@keyframes wobble {
    0% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(10px, 30px);
    }

    100% {
        transform: translate(0, 0);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(40deg);
    }

    50% {
        transform: rotate(220deg);
    }

    100% {
        transform: rotate(400deg);
    }
}